<template>
  <div class="profileConnectionModal">
    <base-heading
      type="2"
      modifiers="center"
      class="profileConnectionModal__title"
    >
      {{ $t('medisanaDisconnectModal.title') }}
    </base-heading>

    <p class="profileConnectionModal__body">
      {{ $t('medisanaDisconnectModal.body') }}
    </p>

    <base-button
      modifiers="secondary"
      class="profileConnectionModal__button"
      v-on:click="disconnect"
    >
      {{ $t('medisanaDisconnectModal.disconnectButtonLabel') }}
    </base-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseButton from '@/components/BaseButton';
import BaseHeading from '@/components/BaseHeading';

export default {
  components: {
    BaseButton,
    BaseHeading,
  },

  methods: {
    ...mapActions('member', ['medisanaLogout']),

    async disconnect() {
      try {
        await this.medisanaLogout();

        this.$emit('close');
      } catch (error) {
        //
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileConnectionModal__body {
  @include paragraph;
  margin: 0 0 rem(32px) 0;
  text-align: center;

  @media (min-width: $breakpoint-md) {
    padding: 0 rem(80px);
  }
}

.profileConnectionModal__button {
  display: block;
  margin: 0 auto;
}
</style>
